<template>
  <b-nav>
    <b-nav-item
      v-b-tooltip.hover.top="$t('MENU_HEADER_UNASSIGNED_RECLAMATION')"
      @click="$router.push({ path: '/reclamation/all/NEW'})"
    >
      <feather-icon
        :badge="stats.noAssigned?stats.noAssigned:'0'"
        badge-classes="bg-danger"
        class="text-danger"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>

    <b-nav-item
      v-b-tooltip.hover.top="$t('MENU_HEADER_PROCESSING_RECLAMATION')"
      @click="$router.push({ path: '/reclamation/my/PROCESSING'})"
    >
      <feather-icon
        :badge="stats.inProgress?stats.inProgress:'0'"
        badge-classes="bg-warning"
        class="text-warning"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>

    <b-nav-item
      v-b-tooltip.hover.top="$t('MENU_HEADER_RESOLVED_RECLAMATION')"
      @click="$router.push({ path: '/reclamation/my/RESOLVED'})"
    >
      <feather-icon
        :badge="stats.resolved?stats.resolved:'0'"
        badge-classes="bg-success"
        class="text-success"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>

    <b-nav-item
      v-b-tooltip.hover.top="$t('MENU_HEADER_CLOSED_RECLAMATION')"
      @click="$router.push({ path: '/reclamation/my/CLOSED'})"
    >
      <feather-icon
        :badge="stats.closed?stats.closed:'0'"
        badge-classes="bg-primary"
        class="text-primary"
        icon="BellIcon"
        size="21"
      />
    </b-nav-item>
  </b-nav>
</template>

<script>
import { BNav, BNavItem, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNav,
    BNavItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    stats() {
      return this.$store.getters['reclamations/getStats']
    },
  },
  mounted() {
    this.$store.dispatch('reclamations/stats')
  },

}
</script>

<style>

</style>
